<template>
  <a-modal
    wrap-class-name="payer-edit"
    :visible="visible"
    :title="$t('pages_pay_254')"
    :mask-closable="false"
    :confirm-loading="loading"
    @cancel="$emit('update:visible', false)"
    @ok="handleSubmit"
  >
    <a-form
      ref="editRef"
      :model="editForm"
      :rules="editRules"
      class="payer-edit-form"
      layout="vertical"
    >
      <a-form-item name="senderName" :label="$t('pages_pay_209')">
        <a-input
          v-model:value="editForm.senderName"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_pay_209')"
        />
      </a-form-item>

      <a-form-item name="certNo" :label="$t('pages_pay_092')">
        <a-input
          v-model:value="editForm.certNo"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_pay_092')"
        />
      </a-form-item>

      <a-form-item name="regionCode" :label="$t('pages_pay_251')">
        <a-select
          v-model:value="editForm.regionCode"
          option-filter-prop="label"
          allow-clear
          show-search
          :placeholder="$t('common_text_005') + $t('pages_pay_251')"
        >
          <a-select-option
            v-for="area in areas"
            :key="area.areaCode"
            :value="area.areaCode"
            :label="`${area.areaCode} - ${area.countryChineseName}`"
          >{{ area.areaCode }} - {{ area.countryChineseName }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="bankName" :label="$t('pages_pay_143')">
        <a-input
          v-model:value="editForm.bankName"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_pay_143')"
        />
      </a-form-item>

      <a-form-item name="bankAccountNo" :label="$t('pages_pay_255')">
        <a-input
          v-model:value="editForm.bankAccountNo"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_pay_255')"
        />
      </a-form-item>

    </a-form>
  </a-modal>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { _pay } from '@/api'
import i18n from '@/locale'
export default {
  name: 'PayerEdit',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    areas: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    const editRef = ref(null)
    const state = reactive({
      loading: false,
      editForm: {
        id: '',
        senderName: '',
        certNo: '',
        regionCode: '',
        bankName: '',
        bankAccountNo: ''
      },
      editRules: {
        senderName: [{ required: true, max: 64 }],
        certNo: [{ required: true, len: 18 }],
        regionCode: [{ required: true }],
        bankName: [{ required: true, max: 256 }]
      }
    })

    const intParams = (data, flag) => {
      for (const i in state.editForm) {
        if (flag) {
          if (data[i]) state.editForm[i] = data[i]
        } else {
          state.editForm[i] = ''
          state.editForm.subjectType = '01'
        }
      }
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(values => {
          state.loading = true
          const data = Object.assign({}, state.editForm, {
            subjectType: '01'
          })
          return _pay.updatePayer(data)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t(state.editForm.id ? 'common_text_044' : 'common_text_043'))
            editRef.value.resetFields()
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    return {
      editRef,
      intParams,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>

</style>
