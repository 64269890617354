<template>
  <div id="payerManage">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_router_035')}`" />
    <div class="common-card-body">
      <div class="flex-align">
        <div class="payer-header" @click="$router.push('/funds/inbounds')">
          <left-outlined class="ic-back" />
          {{ $t('pages_pay_191') }}
        </div>
        <div class="edit-btn">
          <a-button shape="round" type="primary" @click="updatePayer(false)">
            <template #icon><plus-outlined /></template>
            {{ $t('pages_pay_256') }}
          </a-button>
          <a-button
            shape="round"
            type="primary"
            ghost
            style="margin-left: 12px"
            :loading="exportLoading"
            @click="exportPayer"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : $t('common_text_033') }}
          </a-button>
        </div>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_250') }}: </span>
          <a-select
            v-model:value="searchQuery.id"
            allow-clear
            show-search
            option-filter-prop="label"
            @change="loadPayerList(1)"
          >
            <a-select-option
              v-for="item in accountNames"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_193') }}: </span>
          <a-input
            v-model:value="searchQuery.acctNo"
            allow-clear
            @pressEnter="loadPayerList(1)"
            @blur="loadPayerList(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="payers"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'senderName'">
              <img style="width: 17px; margin: -5px 5px 0 0px" src="~@/assets/icons/ic-person.png">
              <span>{{ record.senderName }}</span>
            </template>
            <template v-if="column.key === 'subjectType'">
              <div class="flex-align">
                <div :class="record.subjectType === '00' ? 'circle-person' : 'circle-company'" />
                <span style="margin-left: 5px">{{ record.subjectTypeText }}</span>
              </div>
            </template>
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: center">
                <a-button
                  size="small"
                  type="link"
                  @click="updatePayer(record.id)"
                >{{ $t('pages_pay_199') }}</a-button>
                <a-divider style="margin: 0" type="vertical" />
                <a-popconfirm :title="$t('pages_pay_200')" @confirm="() => deletePayer(record.id)">
                  <a-button
                    size="small"
                    type="link"
                    danger
                  >{{ $t('pages_pay_201') }}</a-button>
                </a-popconfirm>
                <a-button
                  shape="round"
                  size="small"
                  type="primary"
                  style="margin-left: 5px"
                  @click="toPay(record.id)"
                >{{ $t('pages_pay_191') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
    <payer-edit
      ref="payerEditRef"
      v-model:visible="visible"
      :areas="areas"
      @refresh="handleRefresh"
    />
  </div>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import PayerEdit from './components/payer-edit'
import { useRouter } from 'vue-router'
import { _pay } from '@/api'
import { getTradeStatusName, getCountryLogo } from '@/util'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'PayerManage',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage,
    'payer-edit': PayerEdit
  },
  setup () {
    const payerEditRef = ref(null)
    const router = useRouter()
    const state = reactive({
      loading: false,
      visible: false,
      exportLoading: false,
      searchQuery: {
        pageNo: 1,
        pageSize: 10,
        id: '',
        acctNo: ''
      },
      total: 0,
      areas: [],
      accountNames: [],
      payers: [],
      columns: [
        { key: 'senderName', title: i18n.global.t('pages_pay_250'), width: 200, ellipsis: true },
        { key: 'bankName', dataIndex: 'bankName', title: i18n.global.t('pages_pay_143') },
        { key: 'bankAccountNo', dataIndex: 'bankAccountNo', title: i18n.global.t('pages_pay_193') },
        { key: 'registerText', dataIndex: 'registerText', title: i18n.global.t('pages_pay_251') },
        { key: 'subjectType', dataIndex: 'subjectTypeText', title: i18n.global.t('pages_pay_252') },
        { key: 'identNo', dataIndex: 'identNo', title: i18n.global.t('pages_pay_253'), width: 200, ellipsis: true },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: 220 }
      ]
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadPayerList()
    }

    const updatePayer = async (id) => {
      if (id) {
        const res = await _pay.getPayerDetail({ id })
        if (res.data.succ) {
          const data = Object.assign({}, res.data.data, {
            certNo: res.data.data.identityNo
          })
          payerEditRef.value.intParams(data, true)
          state.visible = true
        }
      } else {
        payerEditRef.value.intParams({}, false)
        state.visible = true
      }
    }

    const deletePayer = (id) => {
      _pay.removePayer({ id })
        .then(res => {
          message.success(i18n.global.t('pages_pay_203'))
          handleRefresh()
        })
        .catch(err => console.log(err))
    }

    const toPay = (payerId) => {
      const query = { payerId }
      router.push({ path: '/funds/inbounds/single_cny', query })
    }

    const exportPayer = async () => {
      state.exportLoading = true
      await _pay.exportPayer(state.searchQuery)
      state.exportLoading = false
    }

    const handleRefresh = () => {
      loadPayerList()
      loadPayerNameList()
    }

    const loadPayerNameList = async () => {
      const res = await _pay.getPayerNameList()
      if (res.data.succ) {
        state.accountNames = res.data.data
      }
    }

    const loadAreaList = async () => {
      const res = await _pay.getRegionList()
      if (res.data.succ) state.areas = res.data.data
    }

    const loadPayerList = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const query = Object.assign({}, state.searchQuery, {
        pageNo: state.searchQuery.pageNum
      })
      delete query.pageNum
      const res = await _pay.getPayerList(state.searchQuery)
      if (res.data.succ) {
        state.payers = res.data.data.list.map(item => {
          const country = state.areas.find(area => area.areaCode === item.regionCode)
          return Object.assign({}, item, {
            subjectTypeText: getTradeStatusName(item.subjectType, 'subjectType'),
            registerText: country ? country.countryChineseName : '-'
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    loadAreaList()
    loadPayerList()
    loadPayerNameList()

    return {
      payerEditRef,
      exportPayer,
      updatePage,
      updatePayer,
      deletePayer,
      toPay,
      handleRefresh,
      getCountryLogo,
      loadPayerList,
      loadAreaList,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payerManage {
  text-align: left;
  .common-card-body {
    padding: 24px;
    .payer-header {
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      .ic-back {
        color: @main-color;
      }
    }
    .edit-btn {
      margin-left: auto;
    }
    .edit-btn:deep(.ant-btn) {
      min-width: 135px;
    }
    .part {
      flex: 0 0 37%;
      .part-label {
        width: 150px;
      }
    }
    .table-part {
      margin: 12px -24px 0 -24px;
      .circle-person, .circle-company {
        width: 6px;
        height: 6px;
        background: @success-color;
        margin-right: 8px;
        border-radius: 50%;
      }
      .circle-company {
        background: @main-color;
      }
    }
  }
}
</style>
